import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/stamford/about-us.webp'


const _hoisted_1 = { class: "st_about_us p-0 grey_light_bg" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<div class=\"container-fluid p-0\" id=\"homeAboutUs\" data-v-c53274f0><div class=\"row g-0\" data-v-c53274f0><div class=\"col-lg-6 d-flex\" data-v-c53274f0><div class=\"img\" data-v-c53274f0><img src=\"" + _imports_0 + "\" alt=\"About Us\" class=\"img-fluid\" data-v-c53274f0></div></div><div class=\"col-lg-6 d-flex justify-content-start align-items-center\" data-v-c53274f0><div class=\"text\" data-v-c53274f0><h2 class=\"title\" data-title=\"ABOUT US\" data-v-c53274f0>ABOUT US</h2><h3 class=\"sub_title\" data-v-c53274f0> South Africa’s trusted brand <br data-v-c53274f0> of reliable tyres </h3><p class=\"text-uppercase letter_space_0\" data-v-c53274f0><b data-v-c53274f0>Stamford provides best, high performance &amp; fuel saving tyres for car, passenger, SUV &amp; truck in South Africa. Stamford Tyres enables movement with reliable tyres known for their durability, smooth ride and secure grip on roads.</b></p><p data-v-c53274f0> We sell only the best tyres for vehicles such as passenger car, AWD SUV &amp; van, and truck/bus. You can find affordable tyres with top-notch quality, and we never compromise with quality when it comes to Falken tyres. We distribute tyres to dealers across South Africa and our highly skilled and capable team provides support and advice to diverse industries. </p></div></div></div></div>", 1)
  ])))
}