import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home st" }
const _hoisted_2 = {
  id: "page-not-found",
  class: "col-12 d-flex justify-content-center align-items-center"
}
const _hoisted_3 = { class: "col-8 col-sm-10 d-flex flex-wrap justify-content-center align-items-center content py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", {
          class: "title text-center h1",
          "data-title": "404"
        }, "404", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-uppercase text-center col-12 h2 proxima_bold my-4 my-lg-5 blurb" }, "We couldn't find what you're looking for", -1)),
        _createVNode(_component_router_link, {
          to: { path: '/' },
          class: "btn red_btn mb-1"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}