import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/stamford/stamford.webp'


const _hoisted_1 = { class: "grey_bg navigation" }
const _hoisted_2 = { class: "container wrapper" }
const _hoisted_3 = { class: "logo" }
const _hoisted_4 = { class: "menu_list" }
const _hoisted_5 = { class: "btn_group_list d-flex d-lg-none" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "btn_group_list d-none d-lg-flex" }
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]

export function render(_ctx, _cache) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, { to: { path: '/'} }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Stamford Tyres",
              class: "img-fluid"
            }, null, -1)
          ])),
          _: 1
        })
      ]),
      _createElementVNode("nav", {
        id: "navMenu",
        class: _normalizeClass(["menu", { active: _ctx.showMobileMenu }]),
        onClick: _cache[3] || (_cache[3] = $event => (_ctx.showMobileMenu = false))
      }, [
        _createElementVNode("div", {
          id: "ToggleIcon",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.showMobileMenu = !_ctx.showMobileMenu)),
          class: _normalizeClass(["menu_bar close_toggle d-block d-lg-none", { active: _ctx.showMobileMenu }])
        }, null, 2),
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _cache[1] || (_cache[1] = $event => (_ctx.goTO('homeAboutUs')))
            }, "ABOUT")
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_router_link, { to: { path: '/stamford-tyre-range'} }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("TYRE BRANDS")
              ])),
              _: 1
            })
          ]),
          _cache[9] || (_cache[9] = _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "https://orders.stamford.co.za/",
              target: "_blank"
            }, "B2B")
          ], -1))
        ]),
        _createElementVNode("ul", _hoisted_5, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "javascript:;",
              onClick: _cache[2] || (_cache[2] = $event => (_ctx.goTO('contactUsHome'))),
              class: "btn red_btn"
            }, "Contact Us")
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.falkenURL,
              class: "btn blue_btn"
            }, "Falken Tyres", 8, _hoisted_6)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.kumhoURL,
              class: "btn black_btn"
            }, "Kumho Tyres", 8, _hoisted_7)
          ])
        ])
      ], 2),
      _createElementVNode("ul", _hoisted_8, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: "javascript:;",
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.goTO('contactUsHome'))),
            class: "btn red_btn"
          }, "Contact Us")
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.falkenURL,
            class: "btn blue_btn"
          }, "Falken Tyres", 8, _hoisted_9)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            target: "_blank",
            href: _ctx.kumhoURL,
            class: "btn black_btn"
          }, "Kumho Tyres", 8, _hoisted_10)
        ])
      ]),
      _createElementVNode("div", {
        id: "ToggleIcon",
        onClick: _cache[5] || (_cache[5] = $event => (_ctx.showMobileMenu = !_ctx.showMobileMenu)),
        class: _normalizeClass(["menu_bar d-block d-lg-none", { active: _ctx.showMobileMenu }])
      }, null, 2),
      _createElementVNode("div", {
        id: "menuOverlay",
        onClick: _cache[6] || (_cache[6] = $event => (_ctx.showMobileMenu = !_ctx.showMobileMenu)),
        class: _normalizeClass(["menu_overlay d-block d-lg-none", { active: _ctx.showMobileMenu }])
      }, null, 2)
    ])
  ]))
}