import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "st_teams black_bg" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "teams_wrapper" }
const _hoisted_4 = { class: "row justify-content-center" }
const _hoisted_5 = { class: "col-sm-6 col-md-4 col-lg-3" }
const _hoisted_6 = { class: "box text-center white" }
const _hoisted_7 = ["id"]
const _hoisted_8 = ["src", "alt", "onClick"]
const _hoisted_9 = { class: "fw_700" }
const _hoisted_10 = { class: "fw_400" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "title text-center white" }, "MEET OUR MANAGEMENT TEAM", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teamMember.managmentTeam, (row, index) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", {
                  class: _normalizeClass(["profile teamMember", index== 0 ?'active' :'']),
                  id: 'teams'+row.id
                }, [
                  _createElementVNode("img", {
                    src: _ctx.getImgUrl(row.profile_image),
                    alt: row.name+' '+row.designation,
                    class: "img-fluid",
                    onClick: $event => (_ctx.teamDetails(row))
                  }, null, 8, _hoisted_8)
                ], 10, _hoisted_7),
                _createElementVNode("h5", _hoisted_9, [
                  _createTextVNode(_toDisplayString(row.name) + " ", 1),
                  _createElementVNode("small", _hoisted_10, _toDisplayString(row.designation), 1)
                ])
              ])
            ]))
          }), 256))
        ])
      ])
    ])
  ]))
}