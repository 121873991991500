<header class="grey_bg navigation">
  <div class="container wrapper">
    <div class="logo">
      <router-link :to="{ path: '/'}">
        <img
          src="@/assets/images/stamford/stamford.webp"
          alt="Stamford Tyres"
          class="img-fluid"
        />
      </router-link>
    </div>
    <nav
      id="navMenu"
      class="menu"
      :class="{ active: showMobileMenu }"
      @click="showMobileMenu = false"
    >
      <div
        id="ToggleIcon"
        @click="showMobileMenu = !showMobileMenu"
        class="menu_bar close_toggle d-block d-lg-none"
        :class="{ active: showMobileMenu }"
      ></div>
      <ul class="menu_list">
        <li><a href="javascript:;" @click="goTO('homeAboutUs')">ABOUT</a></li>
        <!-- <li class="dropdown"> -->
        <li>
          <router-link :to="{ path: '/stamford-tyre-range'}"
            >TYRE BRANDS</router-link
          >
        </li>
        <li>
          <a href="https://orders.stamford.co.za/" target="_blank">B2B</a>
        </li>
      </ul>
      <ul class="btn_group_list d-flex d-lg-none">
        <li>
          <a href="javascript:;" @click="goTO('contactUsHome')" class="btn red_btn"
            >Contact Us</a
          >
        </li>
        <li>
          <a target="_blank" :href="falkenURL" class="btn blue_btn"
            >Falken Tyres</a
          >
        </li>
        <li>
          <a target="_blank" :href="kumhoURL" class="btn black_btn"
            >Kumho Tyres</a
          >
        </li>
      </ul>
    </nav>

    <ul class="btn_group_list d-none d-lg-flex">
      <li>
        <a href="javascript:;" @click="goTO('contactUsHome')" class="btn red_btn"
          >Contact Us</a
        >
      </li>
      <li>
        <a target="_blank" :href="falkenURL" class="btn blue_btn"
          >Falken Tyres</a
        >
      </li>
      <li>
        <a target="_blank" :href="kumhoURL" class="btn black_btn"
          >Kumho Tyres</a
        >
      </li>
    </ul>

    <div
      id="ToggleIcon"
      @click="showMobileMenu = !showMobileMenu"
      class="menu_bar d-block d-lg-none"
      :class="{ active: showMobileMenu }"
    ></div>
    <div
      id="menuOverlay"
      @click="showMobileMenu = !showMobileMenu"
      class="menu_overlay d-block d-lg-none"
      :class="{ active: showMobileMenu }"
    ></div>
  </div>
</header>
