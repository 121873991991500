import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "spinner-container"
}

export function render(_ctx, _cache) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "spinner-bg" }, [
          _createElementVNode("svg", {
            class: "spinner",
            viewBox: "0 0 50 50"
          }, [
            _createElementVNode("circle", {
              class: "path",
              cx: "25",
              cy: "25",
              r: "20",
              fill: "none",
              "stroke-width": "5"
            })
          ])
        ], -1)
      ])))
    : _createCommentVNode("", true)
}