import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/stamford/falken.webp'


const _hoisted_1 = { class: "st_visit_falken" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = ["href"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "falken Logo"
              })
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "heading_title" }, [
              _createTextVNode(" TAKING HIGH "),
              _createElementVNode("br"),
              _createTextVNode(" PREMIUM PERFORMANCE "),
              _createElementVNode("br"),
              _createTextVNode(" FROM RACETRACK TO ROAD. ")
            ], -1)),
            _createElementVNode("a", {
              target: "_blank",
              href: _ctx.falkenURL,
              class: "btn falken blue_btn"
            }, "visit falken tyres", 8, _hoisted_6)
          ])
        ])
      ])
    ])
  ]))
}