const config = {
  managmentTeam: [
    {
      id: "1",
      name: "José Romero",
      designation: "General Manager",
      profile_image: "jose-romero.webp",
      image: "jose-romero.webp",
      description:
        "José joined Stamford Tyres in July 2021, he has a proven background of directorship of sales and turning profits towards a favourable position for a company. His extensive experience in the tyre industry has afforded him deep understanding of the market place. Given Jose’s passion about developing and growing the business through mentorship, he is ideally positioned to lead the Stamford Tyres team towards enhancing the organisation’s image and exceeding overall growth objectives. José looks forward to continuing and building on customer relationships over the years to come.",
      status: "active",
    },
    {
      id: "2",
      name: "Fazlin Ally",
      designation: "Financial Manager",
      profile_image: "fazlin-ally.webp",
      image: "fazlin-ally.webp",
      description:
        "Fazlin is an accomplished qualified financial professional with over 15 years’ experience in financial reporting, analysis and financial consolidations. She joined Stamford Tyres Africa in 2013 as a Financial Manager. She is responsible for cash flow management, month end close, budgeting/forecasting and process improvements within the accounting department. She has been exposed to the tyre industry for 8 years and the experience has broadened her horizon in a commercial fast paced environment placing her qualifications and vast experience to full capacity. Her goals are to be an efficient business partner to Operations with the team working together to grow the Business from strength to strength.",
      status: "deactive",
    },
    {
      id: "3",
      name: "Richard Brews",
      designation: "Business Development Manager",
      profile_image: "richard-brews.webp",
      image: "richard-brews.webp",
      description:
        "Richard joined Stamford Tyres in May 2022 and has over 10 years’ experience in the tyre industry. He carries a strong sales background having lead multiple sales teams in the past.  As Business Development Manager, the goal is to grow the Falken Brand through intensive product knowledge and broaden the product portfolio on offer to the market. Richard aims to ensure the value proposition of the Falken Brand is transferred correctly to the market to ensure sustainable growth of the brand over time.",
      status: "deactive",
    },
    {
      id: "4",
      name: "Elcano Wilson",
      designation: "National Warehouse Manager",
      profile_image: "elcano-wilson.webp",
      image: "elcano-wilson.webp",
      description:
        "Elcano joined Stamford Tyres in 2010 which consists of managing the warehouse division nationally, including three warehouses, the warehouse managers, stock and fleet in compliance with company’s policies and vision. Elcano responsibilities consist of overseeing receiving, warehousing, distribution and maintenance of operations nationally. His work goal is to achieve company’s long and short term monitory whilst keeping in line with budget goals.",
      status: "deactive",
    },
    {
      id: "5",
      name: "Dinesh Singh",
      designation: "Regional Sales Manager Durban",
      profile_image: "dinesh-singh.webp",
      image: "dinesh-singh.webp",
      description:
        "Dinesh started his career in 1995 as a counter sales for a well- known tyre dealership from which he gained 12 years of product knowledge in the tyre industry. He joined Stamford Tyres in 2007 and manages a team of 2 sales representatives and overlooks the warehouse team. His main role as a Sales Manager is to lead and guide the sales team. On a daily I motivate, set goals and build a sales plan to achieve targets. My work goals are to enhance career development in the workplace which will allow me to progress professionally and nurture the progress of my team.",
      status: "deactive",
    },
    {
      id: "6",
      name: "Sean Forrester",
      designation: "Regional Sales Manager Johannesburg",
      profile_image: "sean-forrester.webp",
      image: "sean-forrester.webp",
      description:
        "Sean joined Stamford Tyres in July 2021 and has 20 years in the tyre industry with a strong customer service and technical background. This has contributed to a listening, understanding and then to guiding and sharing philosophy. Sean will be assisting the team of sales representatives from the Johannesburg office covering the greater part of the inland region, some areas of the Eastern Cape. Sean aims to support, motivate and assist the sales team to achieve the best performance for the overall benefit of the company.",
      status: "deactive",
    },
    {
      id: "7",
      name: "Salome Kgoale",
      designation: "Marketing Specialist",
      profile_image: "salome-kgoale.webp",
      image: "salome-kgoale.webp",
      description:
        "Salome joined Stamford years in 2021 to head the Marketing for the company. She has over 10 years’ experience with a successful track record in Digital Marketing acquired from different industries ranging from Fintech to the Tyre Industry. Her role as a Marketing Specialist is to create and distribute content, following marketing best practices to create leads.  She ensures that the brands under the Stamford umbrella have meaningful and impactful engagement with clients.",
      status: "deactive",
    },
    {
      id: "8",
      name: "Muhammed Daya",
      designation: "National Wheel Manager",
      profile_image: "muhammed-daya.webp",
      image: "muhammed-daya.webp",
      description:
        "Muhammed  joined Stamford Tyres in October 2022 and has over 10 years’ experience in the tyre industry. As National Wheel Manager, my goal is to grow the SSW and Diesel Brands through intensive product knowledge. Muhammed will be building repeat business and customer loyalty by showing confidence and attention to detail to build trust. Muhammed will be assisting the sales team nationally and aims to support, motivate and assist the sales team to achieve the best performance for the overall benefit of the company.",
      status: "deactive",
    },
  ]
};

export default config;
