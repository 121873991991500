<template>
  <router-view />
  <Footer />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Footer from "@/components/Footer/Footer.vue";
import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";
import { useRoute, useRouter, RouteLocationNormalizedLoaded, Router } from "vue-router";

export default defineComponent({
  components: {
    Footer
  },
  data() {
    let recapthcaObject : IReCaptchaComposition | any = undefined
    const $route : RouteLocationNormalizedLoaded = useRoute()
    const $router : Router = useRouter()
    return {
      recapthcaObject,
      $route,
      $router
    }
  },
  computed: {
    routeCurrentGet (): string {
      return this.$route.name?.toString() || ""
    },
  },
  watch: {
    '$route' (to, from) {
      var routeName = to.name;
      if (routeName == 'stamford-home' || routeName =='stamford-tyre-range') {
        var link : any
        link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        document.title = 'Stamford Tyres';
        link.rel = 'icon';
        link.href = '/favicon.ico';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  },
  created() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;
    this.hideRecaptcha();
  },
  methods: {
    async hideRecaptcha() {
      if (this.recapthcaObject) {
        // Wait until recaptcha has been loaded.
        await this.recapthcaObject.recaptchaLoaded();

        // Hide recaptcha has been loaded.
        const reCapthca = document.querySelector(".grecaptcha-badge") as any;
        if (reCapthca && (this.$route.name !== 'stamford-home')) {
          reCapthca.style.visibility = `hidden`;
        } else {
          reCapthca.style.visibility = `visible`;
        }
      }
    }
  }
});
</script>
<style lang="scss">
/* @import "./assets/scss/variables.scss"; */
/* @import "./assets/scss/mixin.scss"; */
@import "./assets/scss/fonts.scss";
@import "./assets/scss/style.scss";

.grecaptcha-badge {
  z-index: 1;
}
</style>
