<template>
  <div class="home st">
    <Header />
    <HomeBannerSlider />
    <HomeOurHistyory />
    <HomeAboutUs />
    <HomeVisitFalken />
    <HomeTeams />
    <HomeSeanForrester />
    <HomeContact />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/stamford/Header/Header.vue";
import HomeBannerSlider from "@/components/stamford/HomeBannerSlider/HomeBannerSlider.vue";
import HomeOurHistyory from "@/components/stamford/HomeOurHistyory/HomeOurHistyory.vue";
import HomeAboutUs from "@/components/stamford/HomeAboutUs/HomeAboutUs.vue";
import HomeVisitFalken from "@/components/stamford/HomeVisitFalken/HomeVisitFalken.vue";
import HomeTeams from "@/components/stamford/HomeTeams/HomeTeams.vue";
import HomeSeanForrester from "@/components/stamford/HomeSeanForrester/HomeSeanForrester.vue";
import HomeContact from "@/components/stamford/HomeContact/HomeContact.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    HomeBannerSlider,
    HomeOurHistyory,
    HomeAboutUs,
    HomeVisitFalken,
    HomeTeams,
    HomeSeanForrester,
    HomeContact,
  }
});
</script>
