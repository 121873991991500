<section class="st_home_slider p-0">
  <div
    id="carouselExampleIndicators"
    class="carousel slide banner_slider_wrapper"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="4"
        aria-label="Slide 5"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="5"
        aria-label="Slide 6"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Falken tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Falken tyres
              </h1>
              <a href="#" class="btn red_btn">Falken tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-1.webp"
            class="d-block w-100"
            alt="Falken Tyres Banner"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Falken tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Falken tyres
              </h1>
              <a href="#" class="btn red_btn">Falken tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-2.webp"
            class="d-block w-100"
            alt="Falken Tyres Banner"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Kumho tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Kumho tyres
              </h1>
              <a href="#" class="btn red_btn">Kumho tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-3.png"
            class="d-block w-100"
            alt="Kumho Tyres Banner"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Kumho tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Kumho tyres
              </h1>
              <a href="#" class="btn red_btn">Kumho tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-4.png"
            class="d-block w-100"
            alt="Kumho Tyres Banner"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Blackhawk tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Blackhawk tyres
              </h1>
              <a href="#" class="btn red_btn">Blackhawk tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-5.webp"
            class="d-block w-100"
            alt="Blackhawk Tyres Banner"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="box">
          <div class="container wrapper">
            <div class="text opacity-0">
              <p class="letter_space text-uppercase">
                <b>Firenza tyres</b>
              </p>
              <h1 class="heading_title fw_700 text-uppercase">
                Firenza tyres
              </h1>
              <a href="#" class="btn red_btn">Firenza tyres</a>
            </div>
          </div>
          <img
            src="@/assets/images/stamford/new-stamford-tyres-banner-6.webp"
            class="d-block w-100"
            alt="Firenza Tyres Banner"
          />
        </div>
      </div>
    </div>
  </div>
</section>
