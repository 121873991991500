import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home st" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HomeBannerSlider = _resolveComponent("HomeBannerSlider")!
  const _component_HomeOurHistyory = _resolveComponent("HomeOurHistyory")!
  const _component_HomeAboutUs = _resolveComponent("HomeAboutUs")!
  const _component_HomeVisitFalken = _resolveComponent("HomeVisitFalken")!
  const _component_HomeTeams = _resolveComponent("HomeTeams")!
  const _component_HomeSeanForrester = _resolveComponent("HomeSeanForrester")!
  const _component_HomeContact = _resolveComponent("HomeContact")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_HomeBannerSlider),
    _createVNode(_component_HomeOurHistyory),
    _createVNode(_component_HomeAboutUs),
    _createVNode(_component_HomeVisitFalken),
    _createVNode(_component_HomeTeams),
    _createVNode(_component_HomeSeanForrester),
    _createVNode(_component_HomeContact)
  ]))
}