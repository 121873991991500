<template>
  <div class="home st">
    <Header />
    <StamfordTryeRange />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/stamford/Header/Header.vue";
import StamfordTryeRange from "@/components/stamford/StamfordTryeRange/StamfordTryeRange.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    Header,
    StamfordTryeRange,
  }
});
</script>
