import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "black_bg white" }
const _hoisted_2 = { class: "container" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, "© " + _toDisplayString(_ctx.year) + " All Rights Reserved | Stamford Tyres Africa (Pty) LTD", 1)
    ])
  ]))
}