import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  class: "st_contact p-0",
  id: "contactUsHome"
}
const _hoisted_2 = { class: "container wrapper" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-5 grey_light_bg" }
const _hoisted_5 = { class: "form_wrap" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "invalid-feedback" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "invalid-feedback" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "invalid-feedback" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = {
  key: 0,
  value: ""
}
const _hoisted_14 = ["value"]
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "invalid-feedback" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "invalid-feedback" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = {
  key: 0,
  class: "col-12 mt-3"
}
const _hoisted_23 = {
  key: 1,
  class: "col-12 mt-3"
}

export function render(_ctx, _cache) {
  const _component_Field = _resolveComponent("Field")
  const _component_Spinner = _resolveComponent("Spinner")
  const _component_Form = _resolveComponent("Form")

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[14] || (_cache[14] = _createStaticVNode("<div class=\"col-lg-7 white_bg\" data-v-97ade4ee><div class=\"contact_us_box\" data-v-97ade4ee><h2 class=\"title\" data-title=\"contact us\" data-v-97ade4ee>contact us</h2><h3 class=\"sub_title\" data-v-97ade4ee> Have a question? <br data-v-97ade4ee> Send us a message and we <br data-v-97ade4ee> will be in touch. </h3><div class=\"contact_details_wrap\" data-v-97ade4ee><p data-v-97ade4ee><b data-v-97ade4ee>CONTACT DETAILS</b></p><ul data-v-97ade4ee><li class=\"icon call\" data-v-97ade4ee><a href=\"tel:0860522322\" target=\"_blank\" data-v-97ade4ee>0860 522 322</a></li><li class=\"icon email\" data-v-97ade4ee><a href=\"mailto:telesales@stamford.co.za\" data-v-97ade4ee>telesales@stamford.co.za</a></li></ul></div><div class=\"add_details_wrap\" data-v-97ade4ee><ul data-v-97ade4ee><li class=\"icon add\" data-v-97ade4ee><b class=\"d-block text-upppercase\" data-v-97ade4ee>CAPE TOWN </b> 10 Ferrule Avenue, Montague Gardens, Cape Town, 7441 </li><li class=\"icon add\" data-v-97ade4ee><b class=\"d-block text-upppercase\" data-v-97ade4ee>JOHANNESBURG</b> 4 Brine Avenue, Chloorkop, Extension 1, 1624, Johannesburg </li><li class=\"icon add\" data-v-97ade4ee><b class=\"d-block text-upppercase\" data-v-97ade4ee>DURBAN</b> 7 Stanhope Pl, Briardene, Durban, 4051 </li></ul></div></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[13] || (_cache[13] = _createElementVNode("h2", {
              class: "title",
              "data-title": "ENQUIRE"
            }, "ENQUIRE", -1)),
            _createVNode(_component_Form, {
              onSubmit: _ctx.onSubmit,
              "validation-schema": _ctx.schema,
              onInvalidSubmit: _cache[5] || (_cache[5] = $event => {_ctx.formSubmission=false;_ctx.sendError=false;})
            }, {
              default: _withCtx(({ errors }) => [
                _createElementVNode("div", _hoisted_6, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", {
                    for: "",
                    class: "form-label"
                  }, "Name:", -1)),
                  _createVNode(_component_Field, {
                    disabled: _ctx.loading,
                    type: "text",
                    name: "name",
                    modelValue: _ctx.form.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.name) = $event)),
                    class: _normalizeClass([{ 'is-invalid': errors.name }, "form-control"])
                  }, null, 8, ["disabled", "modelValue", "class"]),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(errors.name), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", {
                    for: "",
                    class: "form-label"
                  }, "Phone:", -1)),
                  _createVNode(_component_Field, {
                    disabled: _ctx.loading,
                    type: "text",
                    name: "phone",
                    modelValue: _ctx.form.phone,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.phone) = $event)),
                    class: _normalizeClass([{ 'is-invalid': errors.phone }, "form-control"])
                  }, null, 8, ["disabled", "modelValue", "class"]),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(errors.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", {
                    for: "",
                    class: "form-label"
                  }, "email:", -1)),
                  _createVNode(_component_Field, {
                    disabled: _ctx.loading,
                    type: "email",
                    name: "email",
                    modelValue: _ctx.form.email,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.form.email) = $event)),
                    class: _normalizeClass([{ 'is-invalid': errors.email }, "form-control"])
                  }, null, 8, ["disabled", "modelValue", "class"]),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(errors.email), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    for: "",
                    class: "form-label"
                  }, "Subject:", -1)),
                  _createVNode(_component_Field, {
                    disabled: _ctx.loading,
                    as: "select",
                    name: "subject",
                    modelValue: _ctx.form.subject,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.form.subject) = $event)),
                    class: _normalizeClass([{ 'is-invalid': errors.subject }, "form-control form-select"]),
                    id: "disabledSelect"
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.form.subject)
                        ? (_openBlock(), _createElementBlock("option", _hoisted_13, "Choose..."))
                        : _createCommentVNode("", true),
                      _createElementVNode("option", { value: _ctx.dealerFormKey }, "Become a dealer", 8, _hoisted_14),
                      _createElementVNode("option", { value: _ctx.enquiryFormKey }, "Send an enquiry", 8, _hoisted_15)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "modelValue", "class"]),
                  _createElementVNode("div", _hoisted_16, _toDisplayString(errors.subject), 1)
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", {
                    for: "",
                    class: "form-label"
                  }, "Message:", -1)),
                  _createVNode(_component_Field, {
                    disabled: _ctx.loading,
                    as: "textarea",
                    name: "message",
                    modelValue: _ctx.form.message,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.form.message) = $event)),
                    class: _normalizeClass([{ 'is-invalid': errors.message }, "form-control"])
                  }, null, 8, ["disabled", "modelValue", "class"]),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(errors.message), 1)
                ]),
                _createElementVNode("button", {
                  disabled: _ctx.loading,
                  type: "submit",
                  class: "btn red_btn",
                  style: {"height":"60px"}
                }, [
                  (!_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_20, "SUBMIT ENQUIRY"))
                    : (_openBlock(), _createElementBlock("span", _hoisted_21, [
                        _createVNode(_component_Spinner, { visible: _ctx.loading }, null, 8, ["visible"])
                      ]))
                ], 8, _hoisted_19),
                (_ctx.formSubmission)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[11] || (_cache[11] = [
                      _createElementVNode("b", null, "Email sent successfully! Thank you.", -1)
                    ])))
                  : (_ctx.sendError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[12] || (_cache[12] = [
                        _createElementVNode("b", null, [
                          _createTextVNode("Error sending form. Please "),
                          _createElementVNode("a", {
                            href: "mailto:telesales@stamford.co.za",
                            class: "text-decoration-none"
                          }, "contact us via email"),
                          _createTextVNode(".")
                        ], -1)
                      ])))
                    : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onSubmit", "validation-schema"])
          ])
        ])
      ])
    ])
  ]))
}