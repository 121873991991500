<section class="st_about_us p-0 grey_light_bg">
  <div class="container-fluid p-0" id ="homeAboutUs">
    <div class="row g-0">
      <div class="col-lg-6 d-flex">
        <div class="img">
          <img
            src="@/assets/images/stamford/about-us.webp"
            alt="About Us"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-6 d-flex justify-content-start align-items-center">
        <div class="text">
          <h2 class="title" data-title="ABOUT US">ABOUT US</h2>
          <h3 class="sub_title">
            South Africa’s trusted brand <br/> of reliable tyres
          </h3>
          <p class="text-uppercase letter_space_0">
            <b
              >Stamford provides best, high performance & fuel saving tyres for
              car, passenger, SUV & truck in South Africa. Stamford Tyres
              enables movement with reliable tyres known for their durability,
              smooth ride and secure grip on roads.</b
            >
          </p>
          <p>
            We sell only the best tyres for vehicles such as passenger car, AWD
            SUV & van, and truck/bus. You can find affordable tyres with
            top-notch quality, and we never compromise with quality when it
            comes to Falken tyres. We distribute tyres to dealers across South
            Africa and our highly skilled and capable team provides support and
            advice to diverse industries.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
