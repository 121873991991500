<section class="st_teams black_bg">
  <div class="container">
    <h2 class="title text-center white">MEET OUR MANAGEMENT TEAM</h2>
    <div class="teams_wrapper">
      <div class="row justify-content-center">
        <div class="col-sm-6 col-md-4 col-lg-3" v-for="(row,index) in teamMember.managmentTeam">
          <div class="box text-center white">
            <div class="profile teamMember" :id="'teams'+row.id" :class="index== 0 ?'active' :''">
              <img
                :src="getImgUrl(row.profile_image)"
                :alt="row.name+' '+row.designation"
                class="img-fluid"
                @click="teamDetails(row)"
              />
              <!-- <img
                src="@/assets/images/stamford/teams/jose-romero.webp"
                :alt="row.name+' '+row.designation"
                class="img-fluid"
                @click="teamDetails(row)"
              /> -->
            </div>
            <h5 class="fw_700">
              {{row.name}} <small class="fw_400">{{row.designation}}</small>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
