import { defineComponent } from 'vue';
import EventBus from '@/EventBus'
export default defineComponent({
  name: 'our-history',
  mounted (){
    var element : any
    EventBus.on('my-event', (evt : any) => {
      if (evt.eventContent == 'homeAboutUs') {
        element = document.getElementById(evt.eventContent);
        if (element) {
          var top = element.offsetTop;
          window.scrollTo(0, top);
        }
      }
    })
  }
});