<section class="st_sean_forrester grey_dark_bg white p-0" id="teamDetails">
  <div class="container-fluid p-0 wrapper">
    <div class="row g-0">
      <div class="col-lg-4 col-xxl-3">
        <div class="img">
          <img
            :src="getImgUrl(image)"
            alt="SEAN FORRESTER"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-8 col-xxl-9">
        <div class="text">
          <h3 class="heading_title fw_700">{{name}}</h3>
          <p><b>{{designation}}</b></p>
          <p>
          {{description}}
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
