import { defineComponent } from 'vue';

export default defineComponent({
  name: 'stamford-trye-range',
  computed: {
    falkenURL() {
      return process.env.VUE_APP_FALKEN_URL
    },
    kumhoURL() {
      return process.env.VUE_APP_KUMHO_URL
    }
  }
});