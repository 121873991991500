<section class="st_our_history">
  <div class="container">
    <div class="row flex-lg-row-reverse">
      <div class="col-lg-5 col-xxl-4">
        <div class="img">
          <img
            src="@/assets/images/stamford/our-history.webp"
            alt="Our History"
            class="img-fluid"
          />
        </div>
      </div>
      <div class="col-lg-7 col-xxl-8">
        <div class="text">
          <h2 class="title" data-title="OUR HISTORY">OUR HISTORY</h2>
          <h3 class="sub_title">
            Stamford Tyres Corporation Limited is listed on the main board of
            the Singapore Exchange and is also one of the largest independent
            tyre and wheel distributors in South East Asia. A subsidiary of
            Stamford Tyres Corporation Limited was formed in South Africa in the
            year 1993 - Stamford Tyres (Africa) (Pty) Ltd.
          </h3>
          <p class="text-uppercase letter_space_0">
            <b
              >Stamford Tyres Group holds a 50 years’ partnership with Sumitomo
              Rubber Industries (SRI) the owner of Japanese tyre named Falken.
              Stamford Tyres (Africa) Pty Ltd, imports and distributes Falken
              tyres in South Africa, Namibia, Botswana, Lesotho and Swaziland.
            </b>
          </p>
          <p>
            The Falken tyre brand was launched in Japan in 1983 by Sumitomo
            Rubber Industries, Ltd (SRI), initially as a flagship High
            Performance passenger brand. Sumitomo Rubber Industries is listed as
            the 5th largest Tyre Manufacture in the World. In 1985, Falken Tyre
            Corporation was opened in North America and the brand has since
            grown to be recognised as one of the world leaders in high
            performance tyres with offices and distribution around the globe.
            With proven racetrack credentials and access to advanced
            manufacturing technology and facilities across 11 international SRI
            factories, the Falken brand now offers a comprehensive range of
            tyres encompassing passenger and SUV/4x4 tyres.
          </p>
          <p>
            Other brands under the Stamford Tyres Group are Blackhawk and Sumo
            Firenza, which is one of the company’s proprietary distribution
            brands.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
