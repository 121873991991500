<section class="st_visit_falken">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="text">
          <div class="logo">
            <img
              src="@/assets/images/stamford/falken.webp"
              alt="falken Logo"
            />
          </div>
          <h2 class="heading_title">
            TAKING HIGH <br />
            PREMIUM PERFORMANCE <br />
            FROM RACETRACK TO ROAD.
          </h2>
          <a target="_blank" :href="falkenURL" class="btn falken blue_btn"
            >visit falken tyres</a
          >
        </div>
      </div>
    </div>
  </div>
</section>
