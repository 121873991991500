import { defineComponent } from 'vue';
import { Form, Field, FormActions, FormValidationResult } from "vee-validate";
import { IReCaptchaComposition, useReCaptcha } from "vue-recaptcha-v3";
import * as Yup from "yup";
import EventBus from '@/EventBus'
import Spinner from '@/components/spinner/Spinner.vue'

export default defineComponent({
  name: 'our-history',
  mounted (){
    var element : any
    EventBus.on('my-event', (evt : any) => {
      if (evt.eventContent == 'contactUsHome') {
        element = document.getElementById("contactUsHome");
        if (element) {
          var team = element.offsetTop;
          window.scrollTo(0, team);
        }
      }
    })
  },
  components: {
    Form,
    Field,
    Spinner
  },
  data() {
    let loading = false;
    let sendError = false;
    let phoneRegExp : any;
    let nameRegExp : any;
    let recapthcaObject : IReCaptchaComposition | any = undefined;
    let formSubmission = false;
    phoneRegExp = '[0-9]{10}';
    nameRegExp = `/^[aA-zZ\s]+$/`;
    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required").matches(/^[aA-zZ\s]+$/, 'Name is not valid'),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid").matches(/^[a-zA-Z0-9@\)\(._-]+$/g, 'Email is invalid'),
      phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
        .required("Phone number is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    });
    return {
      loading,
      schema,
      sendError,
      recapthcaObject,
      formSubmission,
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
      },
    };
  },
  created() {
    this.recapthcaObject = useReCaptcha() as IReCaptchaComposition;
  },
  computed: {
    dealerFormKey() {
      return process.env.VUE_APP_FORM_KEY_DEALER
    },
    enquiryFormKey() {
      return process.env.VUE_APP_FORM_KEY_CONTACT
    }
  },
  methods: {
    onSubmit(values:any, formActions:any) {
      this.formSubmission = false;
      this.sendError = false;
      this.recaptcha(formActions)
    },
    async recaptcha(formActions:any) {
      if (this.recapthcaObject) {
        this.loading = true;
  
        // Wait until recaptcha has been loaded.
        await (this.recapthcaObject as IReCaptchaComposition).recaptchaLoaded();
  
        // Execute reCAPTCHA with action
        const token = await (this.recapthcaObject as IReCaptchaComposition).executeRecaptcha(`${this.form.subject}`);
  
        // Do stuff with the received token.
        this.loading = false;
        this.sendQuery(token, formActions);
      }
    },
    async sendQuery(recaptchaResult: string, formActions:any) {
      this.loading =  true;
      const FormContent = this.form;
      const formKey = this.form.subject;
      const endpoint = process.env.VUE_APP_FORM_ENDPOINT ? process.env.VUE_APP_FORM_ENDPOINT + formKey: '';
      const store_only = process.env.VUE_APP_FORM_STORE_ONLY === 'true' ? true : false;
      const options: any = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          GoogleRecaptchaResponse: recaptchaResult,
          FormContent,
          StoreOnly: store_only,
        }),
      };
      try {
        const res = await fetch(endpoint, options).then((result) => {
          if (result.status === 200) {
            this.formSubmission = true;
            this.loading = false;
            formActions.resetForm();
            return result;
          } else {
            this.sendError = true;
            this.loading =  false;
          }
        });
      } catch (e: any) {
        this.sendError = true;
        this.loading =  false;
        throw new Error(e);
      }
    }
  },
});